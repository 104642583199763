<template>
  <v-text-field
    :label="label"
    :name="label"
    :value="value"
    :placeholder="placeholder"
    @input="input($event)"
    @blur="blur"
    outlined
    :disabled="disabled"
    color="rgba(0, 0, 0, 0.87)"
    :error-messages="valueErrors"
    :required="required"
    :class="customClass"
  />
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      required: false,
      default: false,
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    textLength: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  mixins: [validationMixin],
  methods: {
    input(e) {
      this.$emit("input", e);
      this.$v.value.$touch();
    },
    blur() {
      this.$v.value.$touch();
    },
  },
  validations() {
    return {
      value: {
        required: this.required ? required : false,
        minLength: this.textLength ? minLength(this.textLength) : false,
        maxLength: this.textLength ? maxLength(this.textLength) : false,
      },
    };
  },
  computed: {
    valueErrors() {
      const errors = [];
      if (!this.$v.value.$dirty) return errors;
      !this.$v.value.required && errors.push(`${this.label} is required`);
      !this.$v.value.minLength &&
        errors.push(`${this.label} must contain ${this.textLength} characters`);
      !this.$v.value.maxLength &&
        errors.push(`${this.label} must contain ${this.textLength} characters`);
      return errors;
    },
  },
};
</script>
